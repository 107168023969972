<div>
  <h3 class="uk-modal-text">You will need to accept cookies for this</h3>
  <div class="content">
    <p><strong>Data Privacy: </strong></p>
    <p>
      This feature requires the acceptance of cookies. Make sure you accept
      them. By clicking "ACCEPT COOKIES" you agree that this website may use
      cookies according to the privacy policy.
    </p>
  </div>
  <button
    class="uk-button uk-button-primary"
    type="button"
    (click)="this.cookies.setCookiesAccepted()"
  >
    Accept cookies
  </button>
</div>
