/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { SelectionToolButtonStateService } from "@components/selection-tools/service/selection-tool-button-state.service";
import { SelectionToolService } from "@components/selection-tools/service/selection-tool.service";
import { APIService } from "@core/services/api.service";
import { BusinessInterfaceRenderService } from "@core/services/business/business.service";
import { Helper } from "@core/services/helper";
import { LayersService } from "@core/services/layers/services/layers.service";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { SelectionScaleService } from "@core/services/selection-scale/selection-scale.service";
import { ToasterService } from "@core/services/toaster.service";
import { isNullOrUndefinedString } from "@services/core.utilities";
import {
  MAPCLICK,
  MAPDIDIUPDATELAYER,
  MAPDRAWCREATED,
  MAPDRAWDELETED,
  MAPDRAWEDITED,
  MAPDRAWEDITSTART,
  MAPDRAWEDITSTOP,
  MAPDRAWSTART,
  MAPLAYERADD,
  MAPLAYERCHANCE,
  MAPLAYERSCONTROLEVENT,
  MAPOVERLAYADD,
  MAPZOOMEND,
  MAPZOOMSTART,
  clickAccuracy,
  country,
  defaultZoomLevel,
  geoserverGetFeatureInfoUrl,
  hectare,
  lau2,
  lau2name,
  maps_order,
  region,
  subdivision,
} from "@services/data.service";
import { CMLayersService } from "app/components/calculation-module/cm-layers.service";
import { environment } from "environments/environment";
import * as L from "leaflet";
import { BehaviorSubject, Subject } from "rxjs";
import LatLng = L.LatLng;

@Injectable()
export class MapService extends APIService implements OnInit, OnDestroy {
  private map: L.DrawMap;
  private areaNutsSelectedLayer: any;
  private zoomlevel: BehaviorSubject<number> = new BehaviorSubject<number>(
    defaultZoomLevel
  );
  public layerArray: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );

  private cmRunning = false;

  // TODO: A modifier
  private clickEventSubject = new Subject<void>(); // Observable source for click
  clickEventSubjectObs = this.clickEventSubject.asObservable(); // Observable stream

  private drawCreatedSubject = new Subject<void>();
  drawCreatedSubjectObs = this.drawCreatedSubject.asObservable();

  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private layersService: LayersService,
    private selectionScaleService: SelectionScaleService,
    private cmLayerService: CMLayersService,
    private selectionToolService: SelectionToolService,
    private helper: Helper,
    private businessInterfaceRenderService: BusinessInterfaceRenderService,
    private selectionToolButtonStateService: SelectionToolButtonStateService
  ) {
    super(http, logger, loaderService, toasterService);
  }
  ngOnInit(): void {
    this.logger.log("MapService/ngOnInit()");
    this.layerArray.next(this.layersService.getLayerArray().keys());
  }

  ngOnDestroy(): void {
    this.logger.log("MapService/ngOnDestroy()");
  }
  getSubscribtionNutsIds() {
    return this.selectionToolService.nutsIdsSubject.asObservable();
  }
  getNbOfLayersSelected() {
    return this.selectionToolService.nbOfLayersSelected;
  }
  /**
   * Update the clickCursorSubject
   */
  clickCursorUpdate() {
    this.clickEventSubject.next();
  }
  /**
   * Update the drawCreatedSubject
   */
  drawCreatedUpdate() {
    this.drawCreatedSubject.next();
  }

  getMap(): L.DrawMap {
    return this.map;
  }

  setCMRunning(val) {
    this.cmRunning = val;
  }
  // Retrieve all map events
  retrieveMapEvent(): void {
    const self = this;
    this.map.on(MAPCLICK, (event: L.LeafletMouseEvent) => {
      self.onClickEvent(self, event);
    });
    this.map.on(MAPLAYERCHANCE, (event: L.LayersControlEvent) => {
      self.onBaselayerChange(self, event);
    });
    this.map.on(MAPZOOMSTART, () => {
      self.onZoomStart(self);
    });
    this.map.on(MAPZOOMEND, () => {
      self.onZoomEnd(self);
    });
    this.map.on(MAPLAYERSCONTROLEVENT, () => {
      self.onLayersControlEvent(self);
    });
    this.map.on(MAPLAYERADD, (e) => {
      self.onLayerAdd(self, e);
    });
    this.map.on(MAPDIDIUPDATELAYER, (event) => {
      self.onDidUpdateLayers(self, event);
    });
    this.map.on(MAPOVERLAYADD, () => {
      self.onOverLayAdd(self);
    });
    this.map.on(MAPDRAWCREATED, (e) => {
      self.onDrawCreated(self, e);
    });
    this.map.on(MAPDRAWEDITED, () => {
      self.onDrawEdited(self);
    });
    this.map.on(MAPDRAWSTART, () => {
      self.onDrawStart(self);
    });
    this.map.on(MAPDRAWEDITSTART, () => {
      self.onDrawEditStart(self);
    });
    this.map.on(MAPDRAWEDITSTOP, (e) => {
      self.onDrawEditStop(self, e);
    });
    this.map.on(MAPDRAWDELETED, () => {
      self.onDrawDeleted(self);
    });
  }

  // Event functions
  onDrawCreated(self, e) {
    self.logger.log("MapService/onDrawCreated");
    const scale_lvl = self.selectionScaleService.getIdFromNuts(
      self.selectionScaleService.getScaleValue()
    );
    self.selectionToolService.drawCreated(e, this.map, scale_lvl);
    self.selectionToolService.setIsPolygonDrawer(false);
    self.drawCreatedUpdate();
  }
  onDrawEdited(self) {
    self.logger.log("MapService/onDrawEdited");
  }
  onDrawStart(self) {
    self.logger.log("MapService/DrawStart");
    self.selectionToolService.toggleActivateTool(true);

    this.selectionToolService.setButtonClearAll(true); // enable button when the drawing starts
  }
  onDrawEditStart(self) {
    self.logger.log("MapService/DrawEditStart");
    self.selectionToolService.toggleActivateTool(true);
  }
  onDrawEditStop(self, e) {
    self.logger.log("MapService/DrawEditStop");
    self.selectionToolService.setAreas();
    self.selectionToolService.toggleActivateTool(false);
  }
  onDrawDeleted(self) {
    self.logger.log("MapService/DrawDeleted");
    self.selectionToolService.clearAll(self.map);
  }
  onLayersControlEvent(self) {}

  onLayerAdd(self, e) {}
  onOverLayAdd(self) {}
  onMeasureStart(self) {}
  onZoomStart(self) {}
  onZoomEnd(self) {
    this.zoomlevel.next(self.map.getZoom());
  }
  recenter() {
    // console.log(this.map.getView())
    // this.map.setZoom(5)
    this.map.setView([47.3, 0.65], 5);
  }
  onDidUpdateLayers(self, e) {
    self.logger.log("MapService/onDidUpdateLayers", e);
  }
  onBaselayerChange(self, e: L.LayersControlEvent) {
    // in this part we manage the selection scale then we refresh the layers
    const scaleLevel = e.name;
    self.logger.log("onBaselayerChange scaleLevel:", scaleLevel);
    if (scaleLevel !== "OSM" && scaleLevel !== "Satellite") {
      self.selectionToolService.clearAll(self.map);
      self.selectionScaleService.setScaleValue(scaleLevel);
      self.selectionToolService.setScaleValue(scaleLevel);
      self.layersService.setCurrentNutsLevel(scaleLevel);
    }

    // changes the actual scale
    this.selectionScaleService.changeScale();
  }
  onClickEvent(self, e: L.LeafletMouseEvent) {
    if (self.cmRunning) {
      self.toasterService.showDangerToaster(
        "To run the calculation module (CM) for your new selection, STOP CM and RUN it again."
      );
    }
    if (self.getScaleValue() === hectare) {
      return;
    }
    if (self.selectionToolService.getPolygonDrawerState()) {
      return;
    }

    self.selectionToolService.activateClickSelectionTool();

    self.logger.log("MapService/click");
    self.selectionToolButtonStateService.enable(true); // opens the selection tools

    // automatic cursor tool selection doesn't work if polygon draw is activated
    if (!self.selectionToolService.getPolygonDrawerState()) {
      self.logger.log("self.selectionToolService.getPolygonDrawerState");

      self.clickCursorUpdate(); // automatic cursor tool selection
    }
    // check if the selection toul is activate
    self.logger.log(
      "MapService/onClickEvent scale",
      self.selectionScaleService.getScaleValue()
    );
    if (self.selectionScaleService.getScaleValue() === country) {
      self.getNutsGeometryFromCountryAfrica(
        e.latlng,
        self.selectionScaleService.getScaleValue()
      );
    } else if (self.selectionScaleService.getScaleValue() === region) {
      self.getNutsGeometryFromRegionAfrica(
        e.latlng,
        self.selectionScaleService.getScaleValue()
      );
    } else if (self.selectionScaleService.getScaleValue() === subdivision) {
      self.getNutsGeometryFromSubregionAfrica(
        e.latlng,
        self.selectionScaleService.getScaleValue()
      );
    } else if (self.selectionScaleService.getScaleValue() === hectare) {
      if (self.layersService.getIsReadyToShowFeatureInfo() === true) {
        self.getHectareGeometryFromClick(
          e.latlng,
          self.selectionScaleService.getScaleValue()
        );
      }
    } else if (self.selectionScaleService.getScaleValue() === lau2) {
      self.getNutsGeometryFromLau2(
        e.latlng,
        self.selectionScaleService.getScaleValue()
      );
    } else {
      //other NUTS
      self.getNutsGeometryFromNuts(
        e.latlng,
        self.selectionScaleService.getScaleValue()
      );
    }
  }
  getZoomLevel(): BehaviorSubject<number> {
    return this.zoomlevel;
  }

  deleteSelectedAreas() {
    this.selectionToolService.deleteSelectedAreas();
  }
  toggleDrawControls() {
    this.selectionToolService.toggleControl(this.map);
  }

  // NUTS management
  getNutsGeometryFromNuts(latlng: LatLng, nuts_level): any {
    this.logger.log("MapService/getNutsGeometryFromNuts");
    const current_nuts_level =
      this.businessInterfaceRenderService.convertNutsToApiName(nuts_level);
    let bbox = latlng.toBounds(clickAccuracy).toBBoxString();
    bbox =
      bbox +
      "&CQL_FILTER=" +
      "stat_levl_=" +
      current_nuts_level +
      " AND " +
      "date=" +
      "2015" +
      "-01-01Z";
    const action = "population";
    const url =
      geoserverGetFeatureInfoUrl +
      action +
      "&STYLES&LAYERS=" +
      environment.prefixWorkspaceName +
      action +
      "&INFO_FORMAT=application/json&FEATURE_COUNT=50" +
      "&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=" +
      bbox;
    this.logger.log("getNutsGeometryFromNuts url:", url);
    return this.getAreaFromScale(url);
  }

  getNutsGeometryFromCountryAfrica(latlng: LatLng, nuts_level): any {
    let bbox = latlng.toBounds(clickAccuracy).toBBoxString();
    const action = "boundaries0";
    const url =
      geoserverGetFeatureInfoUrl +
      action +
      "&STYLES&LAYERS=" +
      environment.prefixWorkspaceName +
      action +
      "&INFO_FORMAT=application/json&FEATURE_COUNT=50" +
      "&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=" +
      bbox;
    this.logger.log("MapService/getNutsGeometryFromCountryAfrica url", url);
    return this.getAreaFromScale(url);
  }
  getNutsGeometryFromRegionAfrica(latlng: LatLng, nuts_level): any {
    let bbox = latlng.toBounds(clickAccuracy).toBBoxString();
    const action = "regions";
    const url =
      geoserverGetFeatureInfoUrl +
      action +
      "&STYLES&LAYERS=" +
      environment.prefixWorkspaceName +
      action +
      "&INFO_FORMAT=application/json&FEATURE_COUNT=50" +
      "&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=" +
      bbox;
    this.logger.log("MapService/getNutsGeometryFromRegionAfrica url", url);
    return this.getAreaFromScale(url);
  }
  getNutsGeometryFromSubregionAfrica(latlng: LatLng, nuts_level): any {
    let bbox = latlng.toBounds(clickAccuracy).toBBoxString();
    const action = "boundaries1";
    const url =
      geoserverGetFeatureInfoUrl +
      action +
      "&STYLES&LAYERS=" +
      environment.prefixWorkspaceName +
      action +
      "&INFO_FORMAT=application/json&FEATURE_COUNT=50" +
      "&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=" +
      bbox;
    this.logger.log("MapService/getNutsGeometryFromSubregionAfrica url", url);
    return this.getAreaFromScale(url);
  }
  // LAU management;
  getNutsGeometryFromLau2(latlng: LatLng, nuts_level): any {
    const bbox = latlng.toBounds(clickAccuracy).toBBoxString();
    const action = lau2name;
    const url =
      geoserverGetFeatureInfoUrl +
      action +
      "&STYLES&LAYERS=hotmaps:" +
      action +
      "&INFO_FORMAT=application/json&FEATURE_COUNT=50" +
      "&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=" +
      bbox;
    this.logger.log("lau2 url", url);
    return this.getAreaFromScale(url);
  }

  /*postHectareCentroid(payload: any): Promise<any> {
    return this.POST(payload, apiUrl + postForOneHectareCentroid);
  }*/

  getAreaFromScale(url): any {
    return this.http.get(url).subscribe((res) => {
      this.selectAreaWithNuts(res);
    });
  }
  getNutsBusiness(scaleLevel) {
    return this.businessInterfaceRenderService.convertNutsToApiName(scaleLevel);
  }

  selectAreaWithHectare(areaSelected: any) {
    // test if polygon tool is activated in order to avoid selecting a nuts during a polygon drawing
    if (!this.selectionToolService.getPolygonDrawerState()) {
      this.logger.log("MapService/selectAreaWithNuts");
      let areaSelectedLayer = false;
      if (this.areaNutsSelectedLayer) {
        areaSelectedLayer = true; // true if an area nuts is selected
      }

      const lng = areaSelected.coordinates[0];
      const lat = areaSelected.coordinates[1];
      const point = L.latLng(lat, lng);
      const selection_id = point;
      const layer = new L.Rectangle(point.toBounds(100));

      this.selectionToolService.addHectareToMultiSelectionLayers(layer);
    }
  }

  selectAreaWithNuts(areaSelected: any) {
    // test if polygon tool is activated in order to avoid selecting a nuts during a polygon drawing
    if (!this.selectionToolService.getPolygonDrawerState()) {
      this.logger.log("MapService/selectAreaWithNuts");
      let areaSelectedLayer = false;
      if (this.areaNutsSelectedLayer) {
        areaSelectedLayer = true; // true if an area nuts is selected
      }
      // remove the layer if there is one
      this.removeAreaSelectedlayer();
      // create an other selection only if this is a new area or if no area is actually selected (highlighted)
      if (isNullOrUndefinedString(areaSelected)) return;
      const areaNutsSelectedLayer = L.geoJSON(areaSelected);
      if (this.selectionToolService.containLayer(areaNutsSelectedLayer) == 0) {
        this.selectionToolService.removeLayerFromMultiSelectionLayers(
          areaNutsSelectedLayer
        );
      } else if (
        this.selectionToolService.containLayer(areaNutsSelectedLayer) == 1
      ) {
        this.selectionToolService.addToMultiSelectionLayers(
          areaNutsSelectedLayer
        );
      } else {
        return;
      }
    }
  }

  createSelection() {
    // this.selectionToolService.manageEditOrCreateLayer(this.areaNutsSelectedLayer, this.map);
  }
  removeAreaSelectedlayer() {
    if (this.areaNutsSelectedLayer) {
      this.logger.log("MapService/removeAreaSelectedlayer");
      this.map.removeLayer(this.areaNutsSelectedLayer);
      delete this.areaNutsSelectedLayer;

      // disable buttons when layer is removed
      this.selectionToolService.setButtonClearAll(false);
      this.selectionToolService.setLoadresultButton(false);
    }
  }

  getSelectionScaleMenu() {
    const titleDiv = L.DomUtil.create("div", "leaflet-control-layers-title");
    const tooltip = this.selectionScaleService.getTooltipMenu();

    if (tooltip.trim()) {
      titleDiv.innerHTML = `Region scale <span style='width: 12px; position: relative; top: -1px;' uk-icon='icon: info;' title="${tooltip}"></span>`;
    } else {
      titleDiv.innerHTML = "Region scale";
    }

    const scaleControlContainer = this.selectionScaleService
      .getSelectionScaleMenu(this.map, this.loaderService)
      .getContainer();

    scaleControlContainer.insertBefore(
      titleDiv,
      scaleControlContainer.firstChild
    );
    // add event tracking to the selectable areas
    let selectableAreas = document.getElementsByClassName('leaflet-control-layers-base')[0] as HTMLElement;
    
    let areaChose = "";
    
    selectableAreas.childNodes.forEach((area) => {
      areaChose = area.childNodes[0].childNodes[1].textContent;
      (area.childNodes[0].childNodes[0] as HTMLElement).setAttribute("onclick","_paq.push(['trackEvent', 'Selectable Areas', 'Change area', '"+areaChose+"']);");
    });
  }

  showOrRemoveLayer(action: string, order: number) {
    this.layersService.showOrRemoveLayer(action, this.map, order);
  }
  showOrRemoveLayerWithBoolean(
    action: string,
    order: number,
    boolean: boolean
  ) {
    boolean
      ? this.layersService.showLayer(action, this.map, order)
      : this.layersService.removeLayer(action, this.map, order);
  }

  setupMapservice(map: L.DrawMap) {
    this.logger.log("MapService/setupMapservice");
    // set the map to the services that needs to get an instance
    this.map = map;
    this.getSelectionScaleMenu();
    this.addLayersControl();
    this.retrieveMapEvent();
    this.layersService.getLayers().addTo(this.map);
    this.cmLayerService.getLayersCM().addTo(this.map);
    this.selectionToolService.getMultiSelectionLayers().addTo(this.map);
  }

  addLayersControl() {
    // OSM Tile Layer
    const osmLayer = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      zIndex: maps_order,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>,' +
        ' Tiles courtesy of <a href="https://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>',
    }).addTo(this.map);

    // Satellite Tile Layer
    const satelliteLayerUrl =
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
    const satelliteLayer = L.tileLayer(satelliteLayerUrl, {
      attribution:
        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC," +
        " USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan,",
    });

    // Reference Layer on top of Satellite
    const referenceLayerUrl =
      "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}";
    const referenceLayer = L.tileLayer(referenceLayerUrl, {
      zIndex: maps_order,
      attribution:
        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC," +
        " USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan,",
    });

    // Group layers to have both images and names
    const satelliteGroupLayer = L.layerGroup([satelliteLayer, referenceLayer]);

    // Add control with buttons
    const baseLayers = {
      Road: osmLayer,
      Satellite: satelliteGroupLayer,
    };

    // title for maps choice
    const titleBackgroundMaps = L.DomUtil.create(
      "div",
      "leaflet-control-layers-title"
    );
    titleBackgroundMaps.innerHTML = "Background maps";

    const backgroundMapCL = L.control.layers(baseLayers, null, {
      collapsed: false,
      position: "topright",
    });
    backgroundMapCL.addTo(this.map);
    // insert title before the first child backgroundMapCL container
    backgroundMapCL
      .getContainer()
      .insertBefore(
        titleBackgroundMaps,
        backgroundMapCL.getContainer().firstChild
      );
      // add event tracking to the selectable maps
      let mapChose = "";
      let backGroundMaps = document.getElementsByClassName('leaflet-control-layers-base')[1] as HTMLElement;
      backGroundMaps.childNodes.forEach((map) => {
        mapChose = map.childNodes[0].childNodes[1].textContent;
        (map.childNodes[0].childNodes[0] as HTMLElement).setAttribute("onclick","_paq.push(['trackEvent', 'Selectable Maps', 'Change map', '"+mapChose+"']);");
      });
  }

  /**
   * Activate the drawing tool
   */
  activateDrawTool(map: L.DrawMap, tool: string) {
    this.selectionToolService.activateDrawTool(map, tool);
  }

  /**
   * Activate the selection tool
   */
  clickSelection() {
    this.selectionToolService.activateClickSelectionTool();
  }

  /**
   * Load the nuts selection results
   */
  loadResultNuts() {
    this.selectionToolService.loadResultNuts(this.map);
  }

  /**
   * Clear the selection(s)
   */
  clearAll() {
    this.selectionToolService.clearAll();
    // this.cmLayerService.clearAll();
  }

  getSelectionSurface(): BehaviorSubject<number> {
    return this.selectionToolService.getSelectionSurface();
  }
  /**
   * Get the nutsSelected Subject of SelectionToolService
   */
  getNutsSelectedSubject(): Subject<number> {
    return this.selectionToolService.getNutsSelectedSubject();
  }

  /**
   * Get the EnableLoadResultSubjectObs of SelectionToolService
   */
  getLoadResultbuttonState() {
    return this.selectionToolService.buttonLoadResultStatus;
  }

  /**
   * Get the DisbleClearAllSubjectObs of SelectionToolService
   */
  getClearAllButtonSubject() {
    return this.selectionToolService.buttonClearAll;
  }

  /**
   * Get the ScaleValueSubject of SelectionScaleService
   */
  getScaleValueSubject(): Subject<string> {
    return this.selectionScaleService.scaleValueSubject;
  }

  /**
   * Get the ScaleValue of SelectionScaleService
   */
  getScaleValue() {
    return this.selectionScaleService.getScaleValue();
  }

  setLayersSubject() {
    const layers = [];
    this.layersService
      .getLayerArray()
      .keys()
      .map((layersName) => {
        layers.push(
          layersName +
            this.businessInterfaceRenderService.getNutsTosuffix(
              this.selectionScaleService.getScaleValue()
            )
        );
      });
    this.layerArray.next(layers);
  }
  getLayerArray() {
    return this.layerArray;
  }

  setLayerWithoutSuffix() {
    const layers = [];
    this.layersService
      .getLayerArray()
      .keys()
      .map((layerName) => {
        layers.push(
          layerName.replace(
            this.businessInterfaceRenderService.getNutsTosuffix(
              this.selectionScaleService.getScaleValue()
            ),
            ""
          )
        );
      });
    return layers;
  }
  displayCustomLayerFromCM(directory, type) {
    this.cmLayerService.addOrRemoveLayerWithAction(directory, type, this.map);
  }
  removeCMLayer() {
    // this.cmLayerService.clearAll()
  }

  // Used to show if the user is using the dev/local API
  setIsDevOrLocalHost() {
    var isDevCondition = ["dev"];
    var isLocalhostCondition = ["localhost"];
    if (
      isDevCondition.some((url) =>
        environment.apiUrl.toLowerCase().includes(url)
      )
    ) {
      return "Development";
    } else if (
      isLocalhostCondition.some((url) =>
        environment.apiUrl.toLowerCase().includes(url)
      )
    ) {
      return "Localhost";
    } else {
      return "";
    }
  }
}
