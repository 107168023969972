<div class="tool-container">
  <button
    [class.uk-button-primary]="isLegendDisplayed"
    class="uk-icon-button uk-button-default"
    uk-icon="paint-bucket"
    (click)="toggleLegend()"
    uk-tooltip="title: Layer legend; pos: bottom"
  ></button>
  <button
    [class.uk-button-primary]="isInfoDisplayed"
    class="uk-icon-button uk-button-default"
    uk-icon="info"
    (click)="toggleInformations()"
    uk-tooltip="title: Information; pos: bottom"
  ></button>

  <!--
  Only for calculation module
  -->
  <button
    *ngIf="dataInteraction.category == calculation_module_name"
    class="uk-icon-button uk-button-default"
    (click)="export()"
    uk-tooltip="title: Download dataset; pos: bottom"
  >
    <img class="icon-download" src="../../../assets/logo_download_all.svg" />
  </button>
  <!--  =========================  -->
  <a
    *ngIf="dataInteraction.download_url"
    href="{{ dataInteraction.download_url }}"
    target="_blank"
    download="{{ dataInteraction.download_url }}"
  >
    <button
      class="uk-icon-button uk-button-default"
      (click)="gaOpenRepo()"
      uk-tooltip="title: Download default dataset; pos: bottom"
    >
      <img
        class="icon-download"
        src="../../../assets/logo_download_all.svg"
      /></button
  ></a>
  <button
    [hidden]="!hasZoneSelected"
    class="uk-icon-button uk-button-default"
    (click)="export()"
    uk-tooltip="title: Download layer selection; pos: bottom"
  >
    <img
      class="icon-download"
      src="../../../assets/logo_download_selection.svg"
    />
  </button>
  <div *ngIf="loading" uk-spinner></div>
</div>

<!--
  Layer legend
-->
<div [hidden]="!isLegendDisplayed" class="uk-card uk-card-default uk-card-body">
  <h3 class="uk-card-title">Legend</h3>
  <div class="card-content">
    <ng-container *ngIf="dataInteraction.custom_symbology">
      <htm-custom-symbology
        [symbolgies]="dataInteraction.custom_symbology"
      ></htm-custom-symbology>
    </ng-container>
    <ng-container *ngIf="!dataInteraction.custom_symbology">
      <img
        [hidden]="!_displayLegend"
        src="{{ _imageUrl }}{{ dataInteraction.layer_type }}"
        (load)="endLoadLegend()"
      />
      <div *ngIf="!_displayLegend" class="loader" uk-spinner></div>
    </ng-container>
  </div>
</div>
<div *ngIf="isLegendDisplayed" class="uk-margin"></div>
<!--
  Layer informations
-->
<div *ngIf="isInfoDisplayed" class="uk-card uk-card-default uk-card-body">
  <h3 class="uk-card-title">Informations</h3>
  <div *ngIf="dataInteraction.description" class="card-content">
    <p>
      Information on the layer can be found here:
      <a href="{{ dataInteraction.description }}" target="_blank"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0,0,256,256"
        >
          <g
            fill="#1e87f0"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <g transform="scale(5.12,5.12)">
              <path
                d="M46.79297,22.08984l-18.88281,-18.88281c-0.80078,-0.80469 -1.85547,-1.20703 -2.91016,-1.20703c-1.05469,0 -2.10937,0.40234 -2.91016,1.20703l-3.73437,3.73437l4.62109,4.62109c1.53516,-0.90234 3.53516,-0.70703 4.85156,0.60938c1.31641,1.31641 1.50781,3.31641 0.60547,4.84766l4.54297,4.54297c1.53516,-0.90234 3.53516,-0.70703 4.85156,0.60938c1.5625,1.5625 1.5625,4.09375 0,5.65625c-1.5625,1.5625 -4.09375,1.5625 -5.65625,0c-1.31641,-1.31641 -1.51172,-3.31641 -0.60937,-4.85156l-4.54297,-4.54297c-0.32422,0.19141 -0.66406,0.33203 -1.01953,0.42188v12.28516c1.72266,0.44531 3,1.99609 3,3.85938c0,2.21094 -1.78906,4 -4,4c-2.21094,0 -4,-1.78906 -4,-4c0,-1.86328 1.27734,-3.41406 3,-3.85937v-12.28516c-0.66797,-0.17187 -1.30469,-0.50391 -1.82812,-1.02734c-1.31641,-1.31641 -1.50781,-3.31641 -0.60547,-4.84766l-4.625,-4.625l-13.73437,13.73438c-1.60938,1.60547 -1.60938,4.21484 0,5.82031l18.88281,18.88281c0.80078,0.80469 1.85547,1.20703 2.91016,1.20703c1.05469,0 2.10938,-0.40234 2.91016,-1.20703l18.88281,-18.88281c1.60938,-1.60547 1.60938,-4.21484 0,-5.82031z"
              ></path>
            </g>
          </g>
        </svg>
        Repository link</a
      >
    </p>
  </div>
</div>
<div *ngIf="isInfoDisplayed" class="uk-margin"></div>
