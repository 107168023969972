import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ChartComponent } from "@components/graph/chart/chart.component";
import { UploadComponent } from "@components/user-management/account/upload/upload.component";
import { UserManagementStatusService } from "@components/user-management/service/user-management-status.service";
import { UserManagementService } from "@components/user-management/service/user-management.service";
import {
  color_unusedspace,
  color_usedspace,
  diskspacechart_options,
  labels_diskspacechart,
} from "@services/data.service";
import { InteractionService } from "@services/interaction.service";
import { Logger } from "@services/logger.service";
import { ToasterService } from "@services/toaster.service";
import { environment } from "environments/environment";

@Component({
  standalone: true,
  selector: "app-file-management",
  templateUrl: "./file-management.component.html",
  styleUrls: ["./file-management.component.css"],
  imports: [
    CommonModule,

    // Components
    ChartComponent,
    UploadComponent,
  ],
})
export class FileManagementComponent implements OnInit {
  public isLoggedIn = false;
  private _token = "";
  private _username = "";
  fileManagement = false;
  firstname = "";
  lastname = "";
  isEditable = false;
  private _diskspaceUsed;
  private _diskspaceMax;
  diskspaceLabels = labels_diskspacechart;
  diskspaceDataset;
  diskspaceOptions = diskspacechart_options;
  public localhost: boolean = environment.apiUrl.includes("localhost");

  constructor(
    private _userManagementService: UserManagementService,
    private _userManagementStatusService: UserManagementStatusService,
    private _toasterService: ToasterService,
    private _interactionService: InteractionService,
    protected logger: Logger
  ) {
    this.logger = logger;
  }

  ngOnInit(): void {
    this.fetchInformation();
  }

  fetchInformation() {
    this._userManagementStatusService.getIsUserLogged().subscribe((data) => {
      this.isLoggedIn = data;
      this.logger.log("User logged", data);
    });
    if (this.isLoggedIn) {
      if (this._userManagementStatusService.getUserToken)
        this._userManagementStatusService
          .getUserToken()
          .subscribe((data) => (this._token = data));

      if (this._userManagementStatusService.getUsername)
        this._userManagementStatusService
          .getUsername()
          .subscribe((data) => (this._username = data));

      this.getAcountInformation();
    }
  }

  openFiles() {
    this.fetchInformation();
    this.fileManagement = true;
  }

  getAcountInformation() {
    this.getUserInformations();
    if (this._userManagementService.getDiskSpace)
      this._userManagementService.getDiskSpace(this._token).then((data) => {
        this._diskspaceMax = data.max_size;
        this._diskspaceUsed = data.used_size;
        this.diskspaceDataset = [
          {
            label: "Account disk",
            backgroundColor: [color_usedspace, color_unusedspace],
            data: [
              this._diskspaceUsed,
              this._diskspaceMax - this._diskspaceUsed,
            ],
          },
        ];
      });
  }
  getUserInformations() {
    if (this._userManagementService.getUserInformations) {
      this._userManagementService
        .getUserInformations(this._token)
        .then((data) => {
          this.logger.log("file-management/getUserInformations()", data);
          this.firstname = data.first_name;
          this.lastname = data.last_name;
        })
        .catch(() => {
          this.setUserIsLoggedOut();
        });
    }
  }

  private setUserIsLoggedOut(msg?: string): void {
    this._userManagementStatusService.setUsername(null);
    this._userManagementStatusService.setUserIsLoggedOut();
    this._userManagementStatusService.setUserToken(null);

    if (msg) this._toasterService.showToaster(msg);
  }

  updateDiskSpace() {
    this.getAcountInformation();
  }
}
