import { AfterContentInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { CookiesService } from "@core/services/cookies.service";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { environment } from "environments/environment";
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  /**
   * management of initial status of general loading
   * AppComponent own general loading
   */
  showLoader: boolean;
  isMap: boolean = true;
  private _isIE: boolean = false;
  favIcon: HTMLLinkElement = document.querySelector("#favicon");

  constructor(
    private _loaderService: LoaderService,
    private _logger: Logger,
    private _router: Router,
    public cookies: CookiesService,
    private titleService: Title
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", "UA-163693658-1", {
          page_path: event.urlAfterRedirects,
        });
      }
    });
    console.log("cookies.cookiesaccepted", this.cookies.cookiesAccepted());
    //Title and favicon add dynamically
    titleService.setTitle(environment.appName + " - Toolbox");
    this.favIcon.href = environment.favicon;
  }

  ngAfterContentInit(): void {
    this.notifyLoaderService();
  }
  /**
   * subcribe the status of the loader service
   * AppComponent own general loading
   */
  notifyLoaderService() {
    this._loaderService.getStatus().subscribe((val: boolean) => {
      this.showLoader = val;
    });
  }

  ngOnDestroy() {
    this._logger.log("AppComponent/ngOnDestroy");
  }

  ngOnInit() {
    this._isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    // This is not working :(
    if (this._isIE) {
      this._router.navigate(["disclaimer_browser"]);
    }

    this._router.events.subscribe(
      (val) =>
        (this.isMap = (val as any).url === "/" || (val as any).url === "/map")
    );

    if (this._router.url === "/register") {
      // console.log('isRegisterRoute')
    }
  }
}
