import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserDisclaimerComponent } from "@pages/browser-disclaimer/browser-disclaimer.component";
import { MapComponent } from "@pages/map/map.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/map",
    pathMatch: "full",
  },
  {
    path: "map",
    component: MapComponent,
  },
  {
    path: "register",
    component: MapComponent,
  },
  // https://citiwattsdev.hevs.ch/recover;token_recover=eyJlbWFpbCI6Imd3ZW5hZWxsZS5ndXN0aW5AaGV2cy5jaCIsImRhdGUiOiIyMDI0LTA1LTIxIDE0OjQ0OjUzLjIzMzcwNSJ9.GS5E5Q.IlY-_OpLe9itaGi9AV8mRuhJJJg
  {
    path: "recover",
    component: MapComponent,
  },
  {
    path: "disclaimer_browser",
    component: BrowserDisclaimerComponent,
  },
  {
    // Wildcard route for a 404 page
    path: "**",
    redirectTo: "/map",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    // Routing
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
