<h1 mat-dialog-title>
  Remove personal layer
</h1>
<div mat-dialog-content>
  Are you sure you want to delete this personal layer?
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="save()">Confirm</button>
  <button mat-button (click)="close()" class="uk-text-danger">Cancel</button>
</div>
