/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 20.10.2020     Albain Dufils - CREM               Creation
 * * 20.10.2020     Daniel Hunacek - HESSO              Creation
 * *
 ******************************************************************/

import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UploadService, UploadedFile } from "@core/services/upload.service";

interface UploadedLayer extends UploadedFile {
  checked?: boolean;
}

// Author CREM Albain Dufils and HESSO Daniel Hunacek
@Component({
  standalone: true,
  selector: "htm-share-layers",
  templateUrl: "./share-layers.component.html",
  styleUrls: ["./share-layers.component.css"],
  imports: [CommonModule],
})
export class ShareLayersComponent implements OnInit {
  constructor(private _uploadService: UploadService) {}
  layers: UploadedLayer[] = [];

  ngOnInit() {
    if (this._uploadService.getShareFiles) {
      this._uploadService.getShareFiles().subscribe((files) => {
        // Only share layer with a type already in layer list
        this.layers = files
          .filter((file) =>
            this._uploadService.filterPersonalOrShareLayerWithTypeInProject(
              file
            )
          )
          .map((file) => {
            // To keep selected layers as selected
            this.layers.forEach((layer) => {
              if (layer.id === file.id)
                (file as UploadedLayer).checked = layer.checked;
            });
            return file;
          });
      });
    }
  }

  actionLayer(layer: UploadedLayer) {
    if (layer.checked) {
      this._uploadService.remove(layer);
      layer.checked = false;
    } else {
      this._uploadService.show(layer);
      layer.checked = true;
    }
  }
}
