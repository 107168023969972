import { Component } from '@angular/core';
import { CookiesService } from '@core/services/cookies.service';

@Component({
  selector: 'htm-accept-cookies',
  standalone: true,
  imports: [],
  templateUrl: './accept-cookies.component.html',
  styleUrl: './accept-cookies.component.css'
})
export class AcceptCookiesComponent {
  constructor(
    public cookies: CookiesService
  ) {}
}
