<div style="padding: 5px">
  <input
    type="file"
    id="csvFileInput"
    (change)="handleFileInput($event)"
    accept=".csv"
  />
  <button
    id="importInputsBtn"
    class="uk-button uk-button-secondary"
    (click)="importInputs()"
    [disabled]="!csvFile"
  >
    Import CSV
  </button>
</div>
